const AppData = {
  BASE_URL_V1: 'https://newstg1-at.kupos.cl/api/v1/',
  BASE_URL_V2_V2: 'https://newstg1-at.kupos.cl/api/v2/',
  BASE_URL: 'https://newstg1-at.kupos.cl/api/v1/',
  BASE_URL_V2: 'https://newstg1-at.kupos.cl/api/v2/',
  BASE_URL_V3: 'https://newstg1-at.kupos.cl/api/v3/',
  PLATFORM_BASE_URL: 'https://newstg1-at.kupos.cl/api/v1/',
  MAP_API_KEY: 'AIzaSyDj_trn6aFeNXQ-KPzOEBO5VNlOFs4tysc',
  clientCode: 'DELFOS',
  clientKey: '222444666',
};
export default AppData;
